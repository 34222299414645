import {
  FunctionComponent,
  SyntheticEvent,
  useState,
  memo,
  Dispatch,
} from 'react';
import _ from 'lodash';
import { WithdrawalSystem } from '~types';
import { useProfile, useConfig } from '~components/hooks';
import { toast } from 'react-toastify';
import { useMutation, gql } from '@apollo/client';
import { Price, addPercent, number } from '~shared/frontend/ui/Price';
import { useYaMetrica } from '~frontend/provider/yametrica';
import { useModal } from './hooks';
import { useTranslation } from 'react-i18next';
import Currency from '~public/frontend/components/Currency';
import Logo from '~public/frontend/scss/images/pay-14.svg';

import Modal from './Modal';

interface Props {
  activeWithdrawSystem: WithdrawalSystem;
  setActiveWithdrawSystem: Dispatch<WithdrawalSystem>;
  setAccount: Dispatch<string>;
  setProps: Dispatch<any>;
}

const Withdraw_Money = gql`
  mutation WithdrawMoney($input: WithdrawalInput!) {
    withdrawalMoney(input: $input) {
      id
      money
      referralMoney
    }
  }
`;

const WithdrawProfile: FunctionComponent = () => {
  const getProfile = useProfile();

  return (
    <div className="pay-ret-user">
      <img src={getProfile.avatar} alt={getProfile.userName} />
      <div className="pay-ret-user-content">
        <div className="name">{getProfile.userName}</div>
        <div className="cost">
          <Price sum={getProfile.referralMoney} />
          <Currency />
        </div>
      </div>
    </div>
  );
};

const WithdrawSystems: FunctionComponent<Props> = memo(
  ({ activeWithdrawSystem, setActiveWithdrawSystem, setAccount, setProps }) => {
    const {
      i18n: { language },
      t,
    } = useTranslation();
    const getConfig = useConfig();
    const [dropdown, setDropdown] = useState(false);

    const onClick = (
      event: SyntheticEvent<HTMLElement>,
      { key } = event?.currentTarget?.dataset,
    ) => {
      event.preventDefault();

      setActiveWithdrawSystem(
        _.find(getConfig?.getWithdrawalSystems, ['key', key]),
      );

      setDropdown(false);
    };

    const onSetDropdown = (event: SyntheticEvent<HTMLElement>) => {
      event.preventDefault();

      if (event.currentTarget.classList.contains('pay-form-input-items')) {
        return false;
      }

      setDropdown(!dropdown);
    };

    const onChange = (event: SyntheticEvent<HTMLInputElement>) => {
      event.preventDefault();

      setAccount(event.currentTarget.value);
    };

    const onChangeProps = (event: SyntheticEvent<HTMLInputElement>) => {
      event.preventDefault();

      setProps({
        [event.currentTarget.getAttribute('name')]: event.currentTarget.value,
      });
    };

    return (
      <>
        <div
          className={`pay-form-method pay-form-method-dropdown ${
            dropdown && 'active'
          }`}
          onClick={onSetDropdown}
        >
          <div className="pay-form-method-info">
            <div className="pay-form-method-title">
              {t('Selected payment method')}:
            </div>
            <div className="pay-form-method-name">
              {activeWithdrawSystem.name}
            </div>
          </div>
          <div className="pay-form-method-logo">
            <img
              src={activeWithdrawSystem.image}
              alt={activeWithdrawSystem.name}
            />
          </div>
          <div className={`pay-form-input-items ${dropdown && 'active'}`}>
            {_.map(getConfig?.getWithdrawalSystems, withdrawalSystem => (
              <div
                key={withdrawalSystem.id}
                className={`pay-methods-item pay-methods-item-popup ${
                  withdrawalSystem.key === activeWithdrawSystem?.key && 'active'
                }`}
                onClick={onClick}
                data-key={withdrawalSystem.key}
              >
                <img src={withdrawalSystem.image} alt={withdrawalSystem.name} />
                <div className="text">{withdrawalSystem.name}</div>
                <div className="pay-methods-item-hover"></div>
              </div>
            ))}
          </div>
        </div>
        <div className="pay-form-input-wrap">
          <div className="pay-form-input">
            <div className="icon material-icons">credit_card</div>
            {activeWithdrawSystem.key.includes('card') ? (
              <input
                type="tel"
                inputMode="numeric"
                pattern="[0-9\s]{13,19}"
                autoComplete="cc-number"
                maxLength={19}
                placeholder={_.result(
                  activeWithdrawSystem.placeholder,
                  `${language}.text`,
                  activeWithdrawSystem?.placeholder?.en,
                )}
                onChange={onChange}
              />
            ) : (
              <input
                type="text"
                placeholder={_.result(
                  activeWithdrawSystem.placeholder,
                  `${language}.text`,
                  activeWithdrawSystem?.placeholder?.en,
                )}
                onChange={onChange}
              />
            )}
          </div>
          {activeWithdrawSystem.key.includes('card') && (
            <div className="pay-form-input pay-form-input-cvv">
              <input
                type="text"
                placeholder="12/29"
                onChange={onChangeProps}
                name="validity"
              />
            </div>
          )}
        </div>
      </>
    );
  },
);

export const Withdraw: FunctionComponent = () => {
  const getConfig = useConfig();
  const [method, setMethod] = useState(0);
  const [account, setAccount] = useState('');
  const [props, setProps] = useState({});
  const modalName = 'Withdraw';
  const { t } = useTranslation();
  const getProfile = useProfile();
  const yametrica = useYaMetrica();
  const { onClose, isOpen } = useModal(modalName);
  const [activeWithdrawSystem, setActiveWithdrawSystem] = useState(
    getConfig?.getWithdrawalSystems[0],
  );

  const [withdrawMoney, { loading }] = useMutation(Withdraw_Money);

  const onClick = async (
    event: SyntheticEvent<HTMLElement>,
    { reachgoal } = event?.currentTarget?.dataset,
  ) => {
    event.preventDefault();

    if (loading) {
      return false;
    }

    try {
      if (method && _.size(account) < 3) {
        throw new Error('Enter payment details');
      }

      await withdrawMoney({
        variables: {
          input: {
            account,
            method: !method ? 'site' : activeWithdrawSystem.key,
            props,
          },
        },
      });

      if (reachgoal) {
        yametrica.reachGoal(reachgoal);
      }

      onClose();
    } catch (error) {
      toast.error(
        <>
          <div className="notify-title">{t('Error')}!</div>
          <div className="notify-text">{t(error.message)}</div>
        </>,
      );
    }
  };

  const onChangeMethod = (
    event: SyntheticEvent<HTMLElement>,
    { type } = event?.currentTarget?.dataset,
  ) => {
    event.preventDefault();

    setMethod(Number(type));
  };

  const onSetProps = (data: any) => {
    setProps({
      ...props,
      ...data,
    });
  };

  return (
    <Modal show={isOpen} onClose={onClose} width={624} modalName={modalName}>
      <div className="box-modal-wrap">
        <div className="box-modal default-popup box-modal-pay-ref">
          <div className="default-popup-wrap">
            <div className="balance-title">{t('Withdrawals')}</div>
            <div
              className="box-modal_close arcticmodal-close"
              onClick={onClose}
            ></div>
            <div className="pay-methods-items">
              <a
                className={`pay-methods-item pay-methods-item-popup ${
                  !method && 'active'
                } ${getProfile.referralMoney <= 0 && 'disabled'}`}
                href="#"
                data-type={0}
                onClick={onChangeMethod}
              >
                <span className="pay-methods-item-label">
                  +1% {t('ref_more_withdraw_on_cscase')}
                </span>
                <img src={Logo} alt={t('CSCASE account')} />
                <div className="text">{t('CSCASE account')} </div>
                <div className="pay-methods-item-hover"></div>
              </a>
              <a
                className={`pay-methods-item pay-methods-item-popup ${
                  method && 'active'
                } ${
                  getProfile.referralMoney < number(getConfig.minSumWithdraw) &&
                  'disabled'
                }`}
                href="#"
                data-type={1}
                onClick={onChangeMethod}
              >
                {getProfile.referralMoney <
                  number(getConfig.minSumWithdraw) && (
                  <div className="disabled-block">
                    <div className="icon material-icons">mood_bad</div>
                    <div className="text">
                      {t('Available from')} {getConfig.minSumWithdraw}
                      <Currency />
                    </div>
                  </div>
                )}
                <img
                  src={activeWithdrawSystem.image}
                  alt={activeWithdrawSystem.name}
                />
                <div className="text">{t('Payment systems')}</div>
                <div className="pay-methods-item-hover"></div>
              </a>
            </div>
            {method ? (
              <WithdrawSystems
                activeWithdrawSystem={activeWithdrawSystem}
                setActiveWithdrawSystem={setActiveWithdrawSystem}
                setAccount={setAccount}
                setProps={onSetProps}
              />
            ) : (
              <WithdrawProfile />
            )}
            <div className="btn-pay-cost-wrap">
              <a
                className="btn-pay-cost btn-pay-cost__big"
                href="#"
                onClick={onClick}
              >
                {!method && getProfile.referralMoney > 0 ? (
                  <span className="btn-sale-tooltipe">
                    <Price sum={getProfile.referralMoney} />
                    <Currency />
                  </span>
                ) : null}
                <div className="icon material-icons">call_received</div>
                <span className="title">{t('ref_request_withdraw')}</span>
                <span className="cost">
                  <span>
                    {!method ? (
                      <Price sum={addPercent(getProfile.referralMoney, 40)} />
                    ) : (
                      <Price sum={getProfile.referralMoney} />
                    )}
                  </span>
                  <Currency />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
